import { Button, Input } from "@mui/material";
import React, { useState } from "react";
import "./PhotoInput.scss";

const PhotoInput = (props) => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const handleProfilePhotoChange = (event) => {
    event.target.files[0]
      ? setFile(URL.createObjectURL(event.target.files[0]))
      : setFile(null);
    props.onChange(event.target.files[0]);
    setFileName(event.target.value);
  };

  const deletePreview = () => {
    URL.revokeObjectURL(file);
    setFile(null);
    setFileName("");
  };

  return (
    <div className="change-photo">
      {file && <img src={file} alt="preview" />}
      <div className="photo-input-wrapper">
        <Input
          multiple={false}
          inputProps={{ accept: "image/*" }}
          type="file"
          value={fileName}
          onChange={handleProfilePhotoChange}
        />
        <div className="button-wrapper">
          <Button onClick={deletePreview} variant="contained">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PhotoInput;
