import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserInfo } from "../../store/actions/user";
import "./UserForm.scss";

const Userform = (props) => {
  const dispatch = useDispatch();
  const { isLoggedIn, userToken } = useSelector((store) => store.loginReducer);
  const { userInfo } = useSelector((store) => store.userReducer);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (userToken !== undefined && userToken !== null)
      dispatch(getCurrentUserInfo(userToken?.id));
  }, [userToken]);

  useEffect(() => {
    if (userInfo != undefined) {
      const name = userInfo.name.split(" ");
      const lastName = name.pop();
      const firstName = name.join(" ");
      setUsername(userInfo.username);
      props?.onUsernameChange(userInfo.username);
      setFirstName(firstName);
      props?.onFirstNameChange(firstName);
      setLastName(lastName);
      props?.onLastNameChange(lastName);
      setNickname(userInfo.nickname);
      props?.onNicknameChange(userInfo.nickname);
      setEmail(userInfo.email);
      props?.onEmailChange(userInfo.email);
    }
  }, [userInfo]);

  const handleUsernameChange = (username) => {
    setUsername(username);
    props?.onUsernameChange(username);
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
    props?.onPasswordChange(password);
  };

  const handlePasswordConfirmChange = (passwordConfirm) => {
    setPasswordConfirm(passwordConfirm);
    props?.onPasswordConfirmChange(passwordConfirm);
  };

  const handleFirstNameChange = (firstName) => {
    setFirstName(firstName);
    props?.onFirstNameChange(firstName);
  };

  const handleLastNameChange = (lastName) => {
    setLastName(lastName);
    props?.onLastNameChange(lastName);
  };

  const handleNicknameChange = (nickname) => {
    setNickname(nickname);
    props?.onNicknameChange(nickname);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    props?.onEmailChange(email);
  };

  return (
    <>
      <div className="form-item">
        <div className="form-item-title">UserID</div>
        <div className="form-item-content">
          <input
            value={username || ""}
            onChange={(e) => handleUsernameChange(e.target.value)}
          />
          <div className="form-requirement">
            User ID must contain between 6 and 30 characters.
          </div>
        </div>
      </div>
      {isLoggedIn ? null : (
        <>
          <div className="form-item">
            <div className="form-item-title">Password</div>
            <div className="form-item-content">
              <input
                value={password || ""}
                onChange={(e) => handlePasswordChange(e.target.value)}
                type="password"
              />
              <div className="form-requirement">
                Password must be longer than 8 characters
              </div>
            </div>
          </div>
          <div className="form-item">
            <div className="form-item-title" id="re-enter">
              Re-enter Password
            </div>
            <div className="form-item-content">
              <input
                value={passwordConfirm || ""}
                onChange={(e) => handlePasswordConfirmChange(e.target.value)}
                type="password"
              />
            </div>
          </div>
        </>
      )}
      <div className="form-item">
        <div className="form-item-title">Name</div>
        <div className="form-item-content">
          <div className="name-wrapper">
            <input
              value={firstName || ""}
              placeholder="First Name  EX) John"
              onChange={(e) => handleFirstNameChange(e.target.value)}
            />
            <input
              value={lastName || ""}
              placeholder="Last Name  EX) Doe"
              onChange={(e) => handleLastNameChange(e.target.value)}
            />
          </div>
          <div className="form-requirement">
            Please Use the name as shown in your HKID / official document
          </div>
        </div>
      </div>
      <div className="form-item">
        <div className="form-item-title">Nickname</div>
        <div className="form-item-content">
          <input
            value={nickname || ""}
            onChange={(e) => handleNicknameChange(e.target.value)}
          />
          <div className="form-requirement">
            This is the name that will be shown with your comments and posts.
            You may use any name you wish.
          </div>
        </div>
      </div>
      <div className="form-item">
        <div className="form-item-title">Email</div>
        <div className="form-item-content">
          {isLoggedIn ? (
            <input id="detail-disabled" value={email || ""} readOnly disabled />
          ) : (
            <input
              value={email || ""}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Userform;
