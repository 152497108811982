import React, { useState, useEffect } from "react";
import "./PlayerMatchImages.scss";

const PlayerMatchImages = ({ playerPhotos }) => {
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("");

  const matchesWithPhotos = playerPhotos.reduce((acc, photo) => {
    const { match_id, photo_url, match_details } = photo;
    if (!acc[match_id]) {
      acc[match_id] = {
        matchId: match_id,
        photos: [],
        matchDetailString: `Match: ${match_details.home_team.name} vs ${match_details.away_team.name}\nLocation: ${match_details.location}\nDate: ${match_details.scheduled_at.date}`,
      };
    }
    acc[match_id].photos.push({ id: photo.id, url: photo_url });
    return acc;
  }, {});

  const uniqueMatches = Object.values(matchesWithPhotos);

  useEffect(() => {
    if (selectedMatchId) {
      const matchPhotos = matchesWithPhotos[selectedMatchId]?.photos;
      if (matchPhotos && matchPhotos.length > 0) {
        setSelectedPhotoUrl(matchPhotos[0].url);
      }
    }
  }, [selectedMatchId]);

  const handleMatchSelection = (matchId) => {
    setSelectedMatchId(matchId);
  };

  const handlePhotoSelection = (url, event) => {
    event.stopPropagation();
    setSelectedPhotoUrl(url);
  };

  return (
    <div className="player-match-images-wrapper">
      <div className="matches-column">
        {uniqueMatches.map((match) => (
          <div
            key={match.matchId}
            className="match-item"
            onClick={() => handleMatchSelection(match.matchId)}
          >
            {match.matchDetailString.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        ))}
      </div>
      <div className="previews-column">
        {selectedMatchId &&
          matchesWithPhotos[selectedMatchId]?.photos.map((photo, index) => (
            <img
              key={index}
              src={photo.url}
              alt={`Preview ${index}`}
              className={selectedPhotoUrl === photo.url ? "selected" : ""}
              onClick={(event) => handlePhotoSelection(photo.url, event)}
            />
          ))}
      </div>
      <div className="current-image-column">
        {selectedPhotoUrl && <img src={selectedPhotoUrl} alt="Current Image" />}
      </div>
    </div>
  );
};

export default PlayerMatchImages;
