import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMyPlayer } from "../../store/actions/user";
import PlayerProfile from "./PlayerProfile";
import PlayerRequest from "./PlayerRequest";
import "./MyPlayer.scss";

const MyPlayer = () => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((store) => store.loginReducer);
  const { playerInfo } = useSelector((store) => store.userReducer);
  const [playerStatus, setPlayerStatus] = useState("");

  useEffect(() => {
    if (userToken !== null && userToken !== undefined) {
      dispatch(getMyPlayer(userToken?.id));
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken?.status === "Inactive") {
      setPlayerStatus("Not Verified Yet. Please verify your email first!");
    } else {
      playerInfo === null || playerInfo === undefined
        ? setPlayerStatus("Member")
        : setPlayerStatus("Player");
    }
  }, [playerInfo]);

  return (
    <>
      <div className="my-player-title">My Player</div>
      <div className="my-player-container">
        <div className="account-type-wrapper">
          <div className="profile-detail-header">Account Type</div>
          <div>{playerStatus}</div>
          <div>
            {userToken?.auth_level !== "User" ? userToken?.auth_level : null}
          </div>
        </div>
        {playerStatus === "Player" ? (
          <PlayerProfile />
        ) : (
          playerStatus === "Member" && <PlayerRequest />
        )}
      </div>
    </>
  );
};

export default MyPlayer;
