import React from "react";
import { Link } from "react-router-dom";
import "./HoverDropdown.scss";
import landingPageHeaderMenu from "../../const/landingPageHeaderMenu";

const DropdownMenuItem = (props) => {
  return (
    <li>
      <Link to={props.path} onClick={props.onClick}>
        {props.title}
      </Link>
    </li>
  );
};

const HoverDropdown = (props) => {
  return (
    <nav className="dropdown-menu">
      <div className="close-button-wrapper">
        <span className="close-button" onClick={props.closeDropdown}>
          X
        </span>
      </div>
      <ul>
        {landingPageHeaderMenu.map((item, index) => {
          return (
            <DropdownMenuItem
              key={index}
              title={item.title}
              path={item.path}
              onClick={props.closeDropdown}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default HoverDropdown;
