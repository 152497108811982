import proAm from "../../logo/roster-backgrounds/proAm.png";
import d1 from "../../logo/roster-backgrounds/d1.png";
import d2 from "../../logo/roster-backgrounds/d2.png";
import d3 from "../../logo/roster-backgrounds/d3.png";
import d4 from "../../logo/roster-backgrounds/d4.png";
import zerve from "../../logo/roster-backgrounds/zerve.png";
import sketchD6 from "../../logo/roster-backgrounds/sketchD6.png";
import youth from "../../logo/roster-backgrounds/youth.png";
import corporateD1 from "../../logo/roster-backgrounds/corporateD1.png";
import corporateD2 from "../../logo/roster-backgrounds/corporateD2.png";
import corporateD3 from "../../logo/roster-backgrounds/corporateD3.png";
import alumni from "../../logo/roster-backgrounds/alumni.png";
import hkkba from "../../logo/roster-backgrounds/hkkba.png";
import sketch from "../../logo/roster-backgrounds/default.png";

const RosterBackground = (leagueCategory, leagueName) => {
  if (leagueCategory === "Youth") return youth;
  if (leagueCategory === "Korean") return hkkba;
  if (leagueCategory === "Alumni") return alumni;
  switch (leagueName) {
    case "Pro-Amateur":
      return proAm;
    case "Division 1":
      return d1;
    case "Division 2":
      return d2;
    case "Division 3":
      return d3;
    case "Division 4":
      return d4;
    case "Division 5":
      return zerve;
    case "Division 6":
      return sketchD6;
    case "Corporate Division 1":
      return corporateD1;
    case "Corporate Division 2":
      return corporateD2;
    case "Corporate Division 3":
      return corporateD3;
    default:
      return sketch;
  }
};

export default RosterBackground;
