import React, { useState } from "react";
import SelectStartersModal from "./SelectStartersModal";
import "./SelectStartersButton.scss";

const SelectStartersButton = (props) => {
  const [openSelectModal, SetOpenSelectModal] = useState(false);

  const handleOpenModal = () => {
    SetOpenSelectModal(true);
  };

  const handleCloseModal = () => {
    SetOpenSelectModal(false);
  };

  const selectTitle = "Please select starters for the match";

  return (
    <div className="select-starters-button-wrapper">
      <button onClick={handleOpenModal}>Edit {props.side} Starting 5</button>
      <SelectStartersModal
        open={openSelectModal}
        onClose={handleCloseModal}
        title={selectTitle}
        teamName={props.team?.name}
        roster={props.roster}
        matchId={props.matchId}
        teamId={props.team?.id}
        onCancel={handleCloseModal}
      />
    </div>
  );
};

export default SelectStartersButton;
