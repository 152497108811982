const defaultStandingTableHeader = [
  "",
  "TEAM",
  "W",
  "L",
  "WIN%",
  "GB",
  "STREAK",
  "+/-",
];

export { defaultStandingTableHeader };
