import sketch from "../logo/post_logo_sketch.png";
import sketchBanner from "../logo/match-banner/sketch_banner.png";
import zerve from "../logo/zerve.png";
import zerveBanner from "../logo/match-banner/zerve_banner.png";
import jointDynamics from "../logo/joint-dynamics.png";
import jointDynamicsBanner from "../logo/match-banner/joint_dynamics_banner.png";
import masterwook from "../logo/masterwook.png";
import masterwookBanner from "../logo/match-banner/masterwook_banner.png";
import cave from "../logo/cave.png";
import caveBanner from "../logo/match-banner/cave_banner.png";
import proamBanner from "../logo/match-banner/proam_banner.png";

const sponsorSelector = (leagueAbv) => {
  let sponsor = "";
  let postLogo = "";
  let sponsorBanner = "";

  switch (leagueAbv) {
    case "Zerve D5":
      sponsor = "Zerve";
      postLogo = zerve;
      sponsorBanner = zerveBanner;
      break;
    case "Joint Dynamics D1":
      sponsor = "Joint Dynamics";
      postLogo = jointDynamics;
      sponsorBanner = jointDynamicsBanner;
      break;
    case "Masterwook D2":
      sponsor = "Masterwook";
      postLogo = masterwook;
      sponsorBanner = masterwookBanner;
      break;
    case "Corporate Cave D2":
      sponsor = "Cave";
      postLogo = cave;
      sponsorBanner = caveBanner;
      break;
    case "Sketch Pro Am":
      sponsor = "Sketch";
      postLogo = sketch;
      sponsorBanner = proamBanner;
      break;
    default:
      sponsor = "Sketch";
      postLogo = sketch;
      sponsorBanner = sketchBanner;
  }

  const sponsorObject = {
    sponsor: sponsor,
    postLogo: postLogo,
    sponsorBanner: sponsorBanner,
  };

  return sponsorObject;
};

export default sponsorSelector;
