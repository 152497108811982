import React from "react";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import "./AccountItem.scss";
import { useSelector } from "react-redux";

const AccountItem = (props) => {
  const { isLoggedIn, userToken } = useSelector((store) => store.loginReducer);
  return (
    <div className="account-wrapper">
      <div
        className="account-icon menu-item"
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {isLoggedIn ? (
          <>
            <span>{userToken?.username}</span>
            <PersonRoundedIcon />
          </>
        ) : (
          <>
            <span>Account</span>
            <PersonOutlineRoundedIcon />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountItem;
