import React from "react";
import { useHistory } from "react-router-dom";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import "./NoticeListItem.scss";
import { TodayISODate } from "../../const/DatetimeISO";

const NoticeListItem = ({ notice, location }) => {
  const history = useHistory();
  const todayDate = TodayISODate();

  return (
    <div className="notice-post-container">
      <div className="notice-post-wrapper">
        <CampaignRoundedIcon className="notice-icon" />
        <div
          className="notice-title"
          onClick={() => {
            history.push(`${location}/${notice.id}`);
          }}
        >
          {notice.title}
        </div>
        <div className="notice-author-datetime-wrapper">
          <span className="notice-author">{notice.username}</span>
          <span className="notice-datetime">
            {notice.created_at.date === todayDate
              ? notice.created_at.time
              : notice.created_at.date}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoticeListItem;
