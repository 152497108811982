export const leagueCategories = [
  "Sketch",
  "Youth",
  "Corporate",
  "Korean",
  "Training",
  "Asian",
  "Alumni",
  "ALeague",
  "BLeague",
  "Others",
  "Premium",
];
