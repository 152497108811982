import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = ({ history }) => {
  history.listen(() => {
    ReactGA.send("pageview"); //new
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
