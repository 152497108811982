import React, { useState, useEffect } from "react";
import "./MatchPhotos.scss";

const MatchPhotos = (props) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);

  const getPhotoUrl = (index) => {
    const normalizedIndex =
      (index + props.matchPhotos.length) % props.matchPhotos.length;
    return props.matchPhotos[normalizedIndex].url;
  };

  const goToPhoto = (index) => {
    const normalizedIndex =
      (index + props.matchPhotos.length) % props.matchPhotos.length;
    setCurrentPhotoIndex(normalizedIndex);
  };

  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };

  // Function to handle key press
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      setShowGallery(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="match-photos-container">
      {showGallery && (
        <div className="photo-gallery-modal">
          <button className="close-gallery" onClick={toggleGallery}>
            X
          </button>
          <div className="gallery-grid">
            {props.matchPhotos.map((photo, index) => (
              <div key={index} className="gallery-item">
                <img src={photo.url} alt={`Photo ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      )}

      {props.matchPhotos && props.matchPhotos.length > 0 ? (
        <>
          <div
            className="preview-photo prev-photo"
            onClick={() => goToPhoto(currentPhotoIndex - 1)}
          >
            <img src={getPhotoUrl(currentPhotoIndex - 1)} alt="Previous" />
          </div>
          <div className="current-photo" onDoubleClick={toggleGallery}>
            <img
              src={getPhotoUrl(currentPhotoIndex)}
              alt="Match"
              title="Double-click to see all photos"
            />
          </div>
          <div
            className="preview-photo next-photo"
            onClick={() => goToPhoto(currentPhotoIndex + 1)}
          >
            <img src={getPhotoUrl(currentPhotoIndex + 1)} alt="Next" />
          </div>
        </>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default MatchPhotos;
