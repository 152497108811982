import React, { useEffect, useState } from "react";
import "./GameResult.scss";
import bball from "../../logo/bball.png";
import TeamNameButton from "../Team/TeamNameButton";
import sponsorSelector from "../../const/sponsorSelector";

const GameResult = ({ matchDetails }) => {
  const TeamNameButtonStyle = {
    color: "Black",
    fontSize: "1.5rem",
  };
  const [sponsorBanner, setSponsorBanner] = useState("");

  useEffect(() => {
    if (matchDetails !== null && matchDetails !== undefined) {
      const sponsorObject = sponsorSelector(matchDetails.league_abv);
      setSponsorBanner(sponsorObject.sponsorBanner);
    }
  }, [matchDetails]);

  return (
    <div className="game-result-container">
      <div className="game-result-wrapper">
        <div className="game-sponsor-banner">
          <img src={sponsorBanner} alt="sponsor-banner" />
        </div>
        <span className="match-title">
          {matchDetails?.league_abv} {matchDetails?.season} -{" "}
          {matchDetails ? matchDetails.match_type : null}
        </span>
        <span className="match-name">{matchDetails?.match_name}</span>
        <div className="game-result">
          <div className="team-wrapper">
            <img
              src={
                matchDetails?.home_team?.logo
                  ? matchDetails.home_team.logo
                  : bball
              }
              className="home-logo"
              alt="home-logo"
            />
            <span>
              {matchDetails?.home_team ? (
                <TeamNameButton
                  ButtonStyle={TeamNameButtonStyle}
                  ButtonSize={"small"}
                  TeamName={matchDetails.home_team?.name}
                  TeamId={matchDetails.home_team?.id}
                />
              ) : null}
            </span>
          </div>
          <div className="game-score">
            {matchDetails?.home_score ? matchDetails.home_score : "--"} :{" "}
            {matchDetails?.away_team ? matchDetails.away_score : "--"}
          </div>
          <div className="team-wrapper">
            <img
              src={
                matchDetails?.away_team?.logo
                  ? matchDetails.away_team.logo
                  : bball
              }
              className="away-logo"
              alt="away-logo"
            />
            <span>
              {matchDetails?.away_team ? (
                <TeamNameButton
                  ButtonStyle={TeamNameButtonStyle}
                  ButtonSize={"small"}
                  TeamName={matchDetails.away_team?.name}
                  TeamId={matchDetails.away_team?.id}
                />
              ) : null}
            </span>
          </div>
        </div>
        <div className="match-sub-title-wrapper">
          <span className="match-sub-title">
            {matchDetails ? matchDetails.scheduled_at.date : "--"}{" "}
            {matchDetails ? matchDetails.scheduled_at.day : "--"} &nbsp;
            {matchDetails ? matchDetails.scheduled_at.time : "--"}
          </span>
          <span className="match-sub-title">
            {" @ "}
            {matchDetails ? matchDetails.location : "--"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GameResult;
