import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import ModalBox from "../Common/ModalBox";
import modalStyle from "../../const/modalStyle";
import { useDispatch } from "react-redux";
import { deleteMatchstats } from "../../store/actions/match";

const DeletePlayersModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const dispatch = useDispatch();

  let players = [];

  if (props.players?.length) {
    players = [...props.players];
    players.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }

  const addPlayer = (e) => {
    if (!e.target.checked) {
      setSelectedPlayers(
        selectedPlayers.filter((item) => item !== e.target.value)
      );
    } else {
      setSelectedPlayers([...selectedPlayers, e.target.value]);
    }
  };

  const handleOpenModal = () => {
    if (selectedPlayers.length === 0) {
      setOpenWarning(true);
      return;
    }
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPlayers([]);
    setOpenWarning(false);
    setOpen(false);
    props.onClose();
  };

  const confirmDelete = () => {
    dispatch(deleteMatchstats(selectedPlayers));
    setIsLoading(true);
  };

  const title = "Confirm to delete from the match roster";
  const message =
    "Do you confirm to delete the selected players from this match?";
  const warningMessage = "Please select at least one player to delete.";
  return (
    <>
      <Modal open={props.open} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <h3 className="modal-title">{props.title}</h3>
          {openWarning && (
            <p style={{ fontStyle: "italic", color: "#ff0000" }}>
              {warningMessage}
            </p>
          )}
          <div className="players-list">
            {players?.map((player) => {
              if (player.name !== "ETC" && player.name !== "Totals")
                return (
                  <div className="player" key={player.id}>
                    <input
                      id={`player-name-${player.name}`}
                      type="checkbox"
                      value={player.id}
                      onChange={(e) => addPlayer(e)}
                    />
                    <label htmlFor={`player-name-${player.name}`}>
                      {player.name}
                    </label>
                  </div>
                );
            })}
          </div>
          <div className="modal-button-wrapper">
            <div className="button-connection" onClick={handleCloseModal}>
              Cancel
            </div>
            <div className="button-connection" onClick={handleOpenModal}>
              Confirm
            </div>
          </div>
        </Box>
      </Modal>
      <ModalBox
        open={open}
        onClose={handleCloseModal}
        title={title}
        message={message}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
        loading={isLoading}
      />
    </>
  );
};

export default DeletePlayersModal;
