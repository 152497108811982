import React, { useState, useEffect } from "react";
import "./MatchRoster.scss";
import { useSelector } from "react-redux";
import SelectPlayersButton from "./SelectPlayersButton";
import MatchPlayers from "./MatchPlayers";
import TeamNameButton from "../Team/TeamNameButton";
import RosterBackground from "./RosterBackground";
import MatchStarters from "./MatchStarters";
import DeletePlayersButton from "./DeletePlayersButton";

const MatchRoster = ({ matchId, isStarted }) => {
  const {
    matchDetails,
    homeTeamRoster,
    awayTeamRoster,
    homeCaptains,
    awayCaptains,
    homeCoaches,
    awayCoaches,
  } = useSelector((store) => store.matchReducer);
  const { leagueCategory } = useSelector((store) => store.leagueReducer);
  const { userToken } = useSelector((store) => store.loginReducer);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHomeCaptain, setIsHomeCaptain] = useState(false);
  const [isAwayCaptain, setIsAwayCaptain] = useState(false);

  useEffect(() => {
    if (
      userToken?.auth_level === "Admin" ||
      userToken?.auth_level === "Super Admin"
    ) {
      setIsAdmin(true);
    }
  }, [userToken]);

  useEffect(() => {
    if (!userToken || isStarted) return;
    if (!homeCaptains) return;
    for (const homeCaptain of homeCaptains) {
      if (homeCaptain.user_id === userToken.id) {
        setIsHomeCaptain(true);
      }
    }
  }, [userToken, homeCaptains]);

  useEffect(() => {
    if (!userToken || isStarted) return;
    if (!homeCoaches) return;
    for (const homeCoach of homeCoaches) {
      if (homeCoach.user_id === userToken.id) {
        setIsHomeCaptain(true);
      }
    }
  }, [userToken, homeCoaches]);

  useEffect(() => {
    if (!userToken || isStarted) return;
    if (!awayCaptains) return;
    for (const awayCaptain of awayCaptains) {
      if (awayCaptain.user_id === userToken.id) {
        setIsAwayCaptain(true);
      }
    }
  }, [userToken, awayCaptains]);

  useEffect(() => {
    if (!userToken || isStarted) return;
    if (!awayCoaches) return;
    for (const awayCoach of awayCoaches) {
      if (awayCoach.user_id === userToken.id) {
        setIsAwayCaptain(true);
      }
    }
  }, [userToken, awayCoaches]);

  const rosterBackground = RosterBackground(
    leagueCategory,
    matchDetails?.league
  );

  return (
    <div className="match-roster-wrapper">
      <div className="match-roster">
        <div className="home-bench">
          <div className="match-team-wrapper">
            <TeamNameButton
              ButtonSize={"small"}
              TeamName={matchDetails?.home_team.name}
              TeamId={matchDetails?.home_team.id}
            />
            {(isAdmin || isHomeCaptain) && (
              <div className="buttons-container">
                <SelectPlayersButton
                  roster={homeTeamRoster}
                  matchId={matchId}
                />
                <DeletePlayersButton
                  players={matchDetails?.home_team.match_stats}
                />
              </div>
            )}
          </div>
          <MatchPlayers players={matchDetails?.home_team.match_stats} />
        </div>
        <div className="starters-container">
          <img src={rosterBackground} className="roster-background" />
          <div className="starters-wrapper">
            <div className="starters">
              <MatchStarters
                isAdmin={isAdmin}
                isCaptain={isHomeCaptain}
                matchId={matchId}
                roster={homeTeamRoster}
                team={matchDetails?.home_team}
                side="Home"
              />
              <MatchStarters
                isAdmin={isAdmin}
                isCaptain={isAwayCaptain}
                matchId={matchId}
                roster={awayTeamRoster}
                team={matchDetails?.away_team}
                side="Away"
              />
            </div>
          </div>
        </div>
        <div className="away-bench">
          <div className="match-team-wrapper">
            <TeamNameButton
              ButtonSize={"small"}
              TeamName={matchDetails?.away_team.name}
              TeamId={matchDetails?.away_team.id}
            />
            <div>
              {(isAdmin || isAwayCaptain) && (
                <div className="buttons-container">
                  <SelectPlayersButton
                    roster={awayTeamRoster}
                    matchId={matchId}
                  />
                  <DeletePlayersButton
                    players={matchDetails?.away_team.match_stats}
                  />
                </div>
              )}
            </div>
          </div>
          <MatchPlayers players={matchDetails?.away_team.match_stats} />
        </div>
      </div>
    </div>
  );
};

export default MatchRoster;
