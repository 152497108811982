import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { validateToken } from "../../store/actions/login";
import Loader from "react-loader-spinner";
import UserValidator from "./UserValidator";
import { resetPassword } from "../../store/actions/user";

const ResetPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { userToken } = useSelector((store) => store.loginReducer);
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const userValidator = new UserValidator();

  useEffect(() => {
    localStorage.setItem("sketch-token", token);
    dispatch(validateToken());
  }, []);

  useEffect(() => {
    if (userToken) {
      setIsLoading(false);
    }
  }, [userToken]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleReNewPasswordChange = (e) => {
    setReNewPassword(e.target.value);
  };

  const confirmReset = (e) => {
    e.preventDefault();
    if (!userValidator.validatePasswordForm(newPassword, reNewPassword)) {
      return;
    }
    dispatch(resetPassword(userToken.id, newPassword));
  };

  return (
    <div className="change-confidential-container">
      {isLoading ? (
        <Loader
          className="post-loader"
          type="Oval"
          color="#ff5722"
          secondaryColor="#757575"
        />
      ) : (
        <>
          <div className="change-confidential-title">Reset Password</div>
          <form className="change-confidential-form" onSubmit={confirmReset}>
            <div className="form-item">
              <div className="form-item-title">New Password</div>
              <div className="form-item-content">
                <input
                  type="password"
                  value={newPassword || ""}
                  required
                  onChange={handleNewPasswordChange}
                />
                <div className="form-requirement">
                  Password must be longer than 8 characters
                </div>
              </div>
            </div>
            <div>
              <div className="form-item">
                <div className="form-item-title">Re-enter New Password</div>
                <div className="form-item-content">
                  <input
                    type="password"
                    value={reNewPassword || ""}
                    required
                    onChange={handleReNewPasswordChange}
                  />
                </div>
              </div>
            </div>
            <div className="save-changes-button-wrapper">
              <button className="save-changes-button" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
