import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import ModalBox from "../Common/ModalBox";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./SelectStartersModal.scss";
import { useDispatch } from "react-redux";
import { updateStarters } from "../../store/actions/match";
import modalStyle from "../../const/modalStyle";

const SelectStartersModal = (props) => {
  const [pointGuard, setPointGuard] = useState("");
  const [shootingGuard, setShootingGuard] = useState("");
  const [smallForward, setSmallForward] = useState("");
  const [powerForward, setPowerForward] = useState("");
  const [center, setCenter] = useState("");
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openRedundantPlayer, setOpenRedundantPlayer] = useState(false);

  let players = [];

  if (props.roster?.length) {
    players = [...props.roster];
    players.sort((a, b) => {
      const aName = a.player_name.toLowerCase();
      const bName = b.player_name.toLowerCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }

  const dispatch = useDispatch();

  const getPosition = (index) => {
    switch (index) {
      case 0:
        return "PG";
      case 1:
        return "SG";
      case 2:
        return "SF";
      case 3:
        return "PF";
      case 4:
        return "C";
      default:
        break;
    }
  };

  const getPositionState = (index) => {
    switch (index) {
      case 0:
        return pointGuard;
      case 1:
        return shootingGuard;
      case 2:
        return smallForward;
      case 3:
        return powerForward;
      case 4:
        return center;
      default:
        break;
    }
  };

  const setPositionState = (index, player) => {
    switch (index) {
      case 0:
        return setPointGuard(player);
      case 1:
        return setShootingGuard(player);
      case 2:
        return setSmallForward(player);
      case 3:
        return setPowerForward(player);
      case 4:
        return setCenter(player);
      default:
        break;
    }
  };

  const addStarter = (e, index) => {
    const starterPosition = getPosition(index);
    setPositionState(index, e.target.value);
    const starter = Object({
      position: starterPosition,
      seasonplayer_id: e.target.value,
      match_id: props.matchId,
      team_id: props.teamId,
    });

    // Check if the player is already selected
    for (let i = 0; i < selectedPlayers.length; i++) {
      if (selectedPlayers[i].seasonplayer_id === starter.seasonplayer_id) {
        if (selectedPlayers[i].position !== starter.position) {
          setOpenRedundantPlayer(true);
          setPositionState(index, "");
          setSelectedPlayers((prev) =>
            prev.filter((player) => player.position !== starter.position)
          );
        }
        return;
      }
    }

    // Check if someone else is already selected for the position
    for (let i = 0; i < selectedPlayers.length; i++) {
      if (selectedPlayers[i].position === starter.position) {
        selectedPlayers[i] = starter;
        setSelectedPlayers([...selectedPlayers]);
        return;
      }
    }

    setOpenRedundantPlayer(false);
    setSelectedPlayers([...selectedPlayers, starter]);
  };

  const handleOpenModal = () => {
    if (selectedPlayers.length === 0) {
      setOpenWarning(true);
      return;
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setPointGuard("");
    setShootingGuard("");
    setSmallForward("");
    setPowerForward("");
    setCenter("");
    setSelectedPlayers([]);
    props.onCancel();
  };

  const confirmSelection = () => {
    setIsLoading(true);
    dispatch(updateStarters(selectedPlayers));
  };

  const title = "Please select starters for the match";
  const message = `Do you confirm to select ${selectedPlayers.length} players as starter(s) for this match?`;
  const warningMessage = `Please select at least 1 player to add to the match.`;
  const redundantPlayerMessage = `Please select one player for one position.`;

  return (
    <>
      <Modal open={props.open} onClose={handleCancel}>
        <Box sx={modalStyle}>
          <h3 className="modal-title">{props.title}</h3>
          {openWarning && (
            <p style={{ fontStyle: "italic", color: "#ff0000" }}>
              {warningMessage}
            </p>
          )}
          {openRedundantPlayer && (
            <p style={{ fontStyle: "italic", color: "#ff0000" }}>
              {redundantPlayerMessage}
            </p>
          )}
          <div className="select-starters-form-wrapper">
            <div className="select-starters">
              <div>{props.teamName}</div>
              <div className="starters-list">
                {Array(5)
                  .fill()
                  .map((_, index) => {
                    return (
                      <FormControl key={index} id="select-starters-form">
                        <InputLabel>{getPosition(index)}</InputLabel>
                        <Select
                          value={getPositionState(index)}
                          onChange={(e) => {
                            addStarter(e, index);
                          }}
                        >
                          {players?.map((player) => {
                            if (player.name !== "ETC")
                              return (
                                <MenuItem value={player.id} key={player.id}>
                                  {player.player_name}
                                </MenuItem>
                              );
                          })}
                        </Select>
                      </FormControl>
                    );
                  })}
              </div>
            </div>
          </div>
          <p style={{ fontStyle: "italic", color: "#727272" }}>
            If you have any enquiry, please contact us.
          </p>
          <div className="modal-button-wrapper">
            <div className="button-connection" onClick={handleCancel}>
              Cancel
            </div>
            <div className="button-connection" onClick={handleOpenModal}>
              Confirm
            </div>
          </div>
        </Box>
      </Modal>
      <ModalBox
        open={openModal}
        onClose={handleCloseModal}
        title={title}
        message={message}
        onCancel={handleCloseModal}
        onConfirm={confirmSelection}
        loading={isLoading}
      />
    </>
  );
};

export default SelectStartersModal;
