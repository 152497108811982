import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getPlayersByPlayerName } from "../../store/actions/player";

const PlayerSearch = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery !== "") {
      dispatch(getPlayersByPlayerName(searchQuery));
    }
  };

  return (
    <>
      <FormControl sx={{ m: 2 }} className="stats-search-input-form">
        <TextField
          id="stats-search-input"
          placeholder="Search Player"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            e.key === "Enter" ? handleSearchSubmit(e) : null;
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="stats-search-button"
                onClick={handleSearchSubmit}
              >
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default PlayerSearch;
