import React from "react";
import Login from "./Login";
import Account from "./Account";
import "./LoginBox.scss";
import { useSelector } from "react-redux";

const LoginBox = (props) => {
  const { isLoggedIn } = useSelector((store) => store.loginReducer);
  return (
    <div className="account-box-wrapper">
      {isLoggedIn ? (
        <Account onMouseLeave={props.toggle} />
      ) : (
        <Login onClick={props.toggle} />
      )}
    </div>
  );
};

export default LoginBox;
