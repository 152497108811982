import React from "react";
import "./BannerBadges.scss";

const BannerBadges = () => {
  return (
    <div className="banner-badges-container">
      {/* <div className="banner-badges-title">Team Badges</div>
      <div className="banner-badges-content">TBD</div> */}
    </div>
  );
};

export default BannerBadges;
