import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Login from "../Common/Login";
import "./ChangeConfidential.scss";

const ChangeConfidential = (props) => {
  const isLoggedIn = useSelector((store) => store.loginReducer.isLoggedIn);
  const history = useHistory();
  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");
  const [thirdInput, setThirdInput] = useState("");
  const [isCalled, setIsCalled] = useState(false);

  useEffect(() => {
    setIsCalled(true);
  }, [isLoggedIn]);

  const returnHome = () => {
    history.push("/");
  };

  const handleFirstInputChange = (value) => {
    setFirstInput(value);
    props.onFirstInputChange(value);
  };

  const handleSecondInputChange = (value) => {
    setSecondInput(value);
    props.onSecondInputChange(value);
  };

  const handleThirdInputChange = (value) => {
    setThirdInput(value);
    props.onThirdInputChange(value);
  };

  return (
    <div className="change-confidential-container">
      <div className="change-confidential-wrapper">
        {isLoggedIn && isCalled ? (
          <>
            <div className="change-confidential-title">
              Change {props.value}
            </div>
            <form
              className="change-confidential-form"
              onSubmit={props.onSubmit}
            >
              <div className="form-item">
                <div className="form-item-title">
                  {props.value === "Email" ? "New Email" : "Old Password"}
                </div>
                <div className="form-item-content">
                  <input
                    type={props.value === "Email" ? "text" : "password"}
                    value={firstInput || ""}
                    required
                    onChange={(e) => handleFirstInputChange(e.target.value)}
                  />
                  <div className="form-requirement">
                    {props.value === "Email"
                      ? "If you change your email, we will send a verification email to your new email address, and you will need to verify it again. "
                      : null}
                  </div>
                </div>
              </div>
              {props.value === "Password" ? (
                <>
                  <div className="form-item">
                    <div className="form-item-title">New Password</div>
                    <div className="form-item-content">
                      <input
                        type="password"
                        value={secondInput || ""}
                        required
                        onChange={(e) =>
                          handleSecondInputChange(e.target.value)
                        }
                      />
                      <div className="form-requirement">
                        Password must be longer than 8 characters
                      </div>
                    </div>
                  </div>
                  <div className="form-item" id="re-enter">
                    <div className="form-item-title">Re-enter New Password</div>
                    <div className="form-item-content">
                      <input
                        type={props.value === "Email" ? "text" : "password"}
                        value={thirdInput || ""}
                        required
                        onChange={(e) => handleThirdInputChange(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="save-changes-button-wrapper">
                <button className="save-changes-button" type="submit">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        ) : (
          isCalled && (
            <div className="not-verified-page-wrapper">
              <div className="verify-page-login">
                <h1>Please Login First!</h1>
                <div id="login-visible">
                  <Login />
                </div>
              </div>
              <div className="return-home-button" onClick={returnHome}>
                Return to Home
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ChangeConfidential;
