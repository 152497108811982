import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import defaultHeaderMenu from "../../const/defaultHeaderMenu";
import "./CategoryHeader.scss";
import sketchLogo from "../../logo/logo.png";
import hkkbaLogo from "../../logo/hkkba_logo.png";
import tatLogo from "../../logo/sketch-international-header.png";
import internationalLogo from "../../logo/sketch-worldcup-header.png";
import corporateLogo from "../../logo/corporate_header_logo.png";
import MenuItem from "./MenuItem";
import HoverDropdown from "./HoverDropdown";
import AccountItem from "./AccountItem";
import LoginBox from "./LoginBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CategoryHeader() {
  const history = useHistory();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);
  const [logo, setLogo] = useState(sketchLogo);
  const [hoverColor, setHoverColor] = useState("#fff700");
  const [openDropdown, setOpenDropdown] = useState(false);

  const toggleShowAccountBox = () => {
    const accountBox = document.querySelector(".account-box");
    accountBox.classList.toggle("hidden");
  };

  const openDropdownMobile = () => {
    if (window.innerWidth > 768) {
      history.push("/");
      return;
    }

    const dropdownMenu = document.querySelector(".dropdown-menu");
    dropdownMenu.classList.remove("closed");
    setOpenDropdown(true);

    if (openDropdown) {
      history.push("/");
      closeDropdownMobile();
    }
  };

  const closeDropdownMobile = () => {
    const dropdownMenu = document.querySelector(".dropdown-menu");
    dropdownMenu.classList.add("closed");
    setOpenDropdown(false);
  };

  const changeHeaderStyle = (color) => {
    const headerWrapper = document.querySelector(".HeaderWrapper");
    const footer = document.querySelector(".footer");
    headerWrapper.setAttribute("style", `background-color: ${color}`);
    footer.setAttribute("style", `background-color: ${color}`);
  };

  const changeHeaderFontColor = (hover) => {
    setHoverColor(hover);
  };

  const setHeaderTheme = (leagueCategory) => {
    let color = "";
    let hover = "";

    switch (leagueCategory) {
      case "Sketch":
        color = "#171e4f"; //navy
        hover = "#ff5722"; //orange
        setLogo(sketchLogo);
        break;
      case "Youth":
        color = "#FDB927"; //yellow
        hover = "#552583"; //purple
        setLogo(sketchLogo);
        break;
      case "Korean":
        color = "#B03036"; //red
        hover = "#102c53"; //navy
        setLogo(hkkbaLogo);
        break;
      case "Corporate":
        color = "#080403"; //black
        hover = "#f2722f"; //light orange
        setLogo(corporateLogo);
        break;
      case "Premium":
        color = "#080403"; //black
        hover = "#f2722f"; //light orange
        setLogo(corporateLogo);
        break;
      case "Training":
        color = "#f2722f"; //light orange
        hover = "#102c53"; //navy
        setLogo(sketchLogo);
        break;
      case "Asian":
        color = "#171e4f"; //navy
        hover = "#ff5722"; //orange
        setLogo(tatLogo);
        break;
      case "Others":
        color = "#552583"; //purple
        hover = "#FDB927"; //yellow
        setLogo(internationalLogo);
        break;
      default:
        color = "#171e4f";
        hover = "#ff5722";
        setLogo(sketchLogo);
    }

    changeHeaderStyle(color);
    changeHeaderFontColor(hover);
  };

  // better to receive images with proper sizes (2:1)
  const setLogoSize = (width, height) => {
    const headerLogo = document.querySelector(".Header-logo");
    if (width !== 0) {
      headerLogo.style.width = `${width}px`;
      headerLogo.style.height = `${height}px`;
    } else {
      headerLogo.style.width = "";
      headerLogo.style.height = "";
    }
  };

  useEffect(() => {
    if (logo === sketchLogo || logo === hkkbaLogo) {
      setLogoSize(0, 0);
    } else {
      setLogoSize(80, 80);
    }
  }, [logo]);

  useEffect(() => {
    if (leagueCategory) {
      setHeaderTheme(leagueCategory);
    }
  }, [leagueCategory]);

  const setFontColorOnHover = (e) => {
    e.target.style.color = hoverColor;
  };

  const setFontColorOnLeave = (e) => {
    if (!e.target.classList.contains("active")) {
      e.target.style.color = "white";
    }
  };

  return (
    <>
      <div className="Header">
        <div className="logo-wrapper">
          <img
            src={logo}
            className="Header-logo"
            alt="logo"
            onClick={openDropdownMobile}
          />
          <ExpandMoreIcon fontSize="large" style={{ color: "white" }} />
          <HoverDropdown closeDropdown={closeDropdownMobile} />
        </div>
        <div className="header-contents">
          {leagueCategory === "Training" ? (
            <div
              className="header-item-wrapper"
              onMouseEnter={setFontColorOnHover}
              onMouseLeave={setFontColorOnLeave}
            >
              <MenuItem
                title="Schedules"
                path={`/${leagueCategory}/Schedules`}
                activeColor={hoverColor}
              />
            </div>
          ) : (
            <>
              {defaultHeaderMenu.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="header-item-wrapper"
                    onMouseEnter={setFontColorOnHover}
                    onMouseLeave={setFontColorOnLeave}
                  >
                    <MenuItem
                      title={item.title}
                      path={`/${leagueCategory + item.path}`}
                      onClick={closeDropdownMobile}
                      activeColor={hoverColor}
                    />
                  </div>
                );
              })}
              <a
                className="menu-item"
                href="https://sketchbball.myshopify.com/"
                onMouseEnter={setFontColorOnHover}
                onMouseLeave={setFontColorOnLeave}
              >
                Shop
              </a>
            </>
          )}
        </div>
        <AccountItem
          onClick={toggleShowAccountBox}
          onMouseEnter={setFontColorOnHover}
          onMouseLeave={setFontColorOnLeave}
        />
      </div>
      <LoginBox toggle={toggleShowAccountBox} />
    </>
  );
}

export default CategoryHeader;
