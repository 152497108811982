import axios from "axios";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_FAIL = "VALIDATE_TOKEN_FAIL";

export function login(username, password) {
  return async (dispatch) => {
    const requestBody = new FormData();
    requestBody.append("username", username);
    requestBody.append("password", password);
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/login`, requestBody)
      .then((res) => {
        localStorage.setItem("sketch-token", res.data.access_token);
        dispatch({ type: LOGIN });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };
}

export function validateToken() {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/me`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        dispatch({
          type: VALIDATE_TOKEN,
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch({ type: VALIDATE_TOKEN_FAIL });
        console.log(err);
      });
  };
}

export function signUp(username, password, name, nickname, email) {
  const requestBody = {
    username: username,
    password: password,
    name: name,
    nickname: nickname,
    email: email,
  };
  return async () => {
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/signup`, requestBody)
      .catch((err) => {
        console.log(err.response);
        throw err.response;
      });
  };
}

export function logOut() {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    localStorage.setItem("sketch-token", "");
  };
}
