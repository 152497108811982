const defaultHeaderMenu = [
  { title: "SKETCH Competition", path: "/Sketch" },
  { title: "SKETCH Premium", path: "/Premium" },
  { title: "SKETCH International", path: "/Asian" },
  { title: "SKETCH Youth", path: "/Youth" },
  // { title: "Corporate", path: "/Corporate" },
  { title: "SKETCH Club", path: "/Training/Schedules" },
  // { title: "Alumni", path: "/Alumni" },
  { title: "HKKBA", path: "/Korean" },
  { title: "SKETCH World Cup", path: "/Others" },
  // { title: "Sponsors", path: "/Sponsors" },
];

export default defaultHeaderMenu;
