import {
  LOGIN,
  LOGOUT,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_FAIL,
} from "../actions/login";
const initialState = {
  isLoggedIn: false,
  userToken: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case VALIDATE_TOKEN:
      return {
        ...state,
        userToken: action.data,
        isLoggedIn: true,
      };
    case VALIDATE_TOKEN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
