const defaultHeaderMenu = [
  { title: "News", path: "/News" },
  { title: "Schedule", path: "/Schedules" },
  { title: "Standings", path: "/Standings" },
  { title: "Statistics", path: "/Statistics" },
  // { title: "Teams", path: "/Teams" },
  // { title: "Youth Class", path: "/Youth" },
  // { title: "Sponsors", path: "/Sponsors" },
];

export default defaultHeaderMenu;
