import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../store/actions/user";
import Login from "../components/Common/Login";
import "./Verify.scss";
import { useHistory, useParams } from "react-router-dom";
import { validateToken } from "../store/actions/login";

const Verify = () => {
  const userId = useParams().userId;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, userToken } = useSelector((store) => store.loginReducer);
  const [message, setMessage] = useState("Your Account Is Now Verified!");
  const [isCalled, setIsCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const returnHome = () => {
    history.push("/");
  };

  useEffect(() => {
    dispatch(validateToken());
  }, [isCalled]);

  useEffect(() => {
    if (userToken && !isCalled) {
      if (userToken?.id !== userId) {
        setMessage("You are not authorized to verify this account!");
        return;
      }
      dispatch(verifyEmail(userToken?.id)).catch((err) => {
        err.status === 400 || err.status === 404
          ? setMessage(err.data.detail)
          : setMessage(err.data.detail[0].msg);
      });
      setIsCalled(true);
    }
    setIsLoading(false);
  }, [userToken]);
  return (
    <div className="verify-page-wrapper">
      <div className="verify-message-container">
        <div className="verify-message-wrapper">
          {isLoggedIn ? (
            <p>{message}</p>
          ) : (
            !isLoading && (
              <div className="verify-page-login">
                <h1>Please Login First!</h1>
                <div id="login-visible">
                  <Login />
                </div>
              </div>
            )
          )}
          <div className="return-home-button" onClick={returnHome}>
            Return to Home
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
