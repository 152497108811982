import React from "react";
import { useHistory } from "react-router";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

const MatchNameButton = ({ matchDetails }) => {
  const history = useHistory();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);

  const playerClickHandler = (matchId) => {
    history.push(`/${leagueCategory}/matches/${matchId}`);
  };

  return (
    <Button
      sx={{
        color: "black",
        fontSize: "0.8rem",
        justifyContent: "flex-start",
      }}
      size={"small"}
      variant="text"
      onClick={(e) => {
        e.stopPropagation();
        playerClickHandler(matchDetails.row.match_id);
      }}
    >
      {matchDetails.row.match_name}
    </Button>
  );
};

const playerMatchStatsStatisticsHeader = [
  {
    field: "match_name",
    headerName: "MATCH",
    flex: 1,
    minWidth: 70,
    sortable: false,
    renderCell: (matchDetails) => {
      return <MatchNameButton matchDetails={matchDetails} />;
    },
  },
  {
    field: "home_team_name",
    headerName: "HOME",
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: "away_team_name",
    headerName: "AWAY",
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: "winloss",
    headerName: "W/L",
    flex: 1,
    minWidth: 60,
    sortable: false,
  },
  {
    field: "PTS",
    headerName: "PTS",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FGM",
    headerName: "FGM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FGA",
    headerName: "FGA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FG%",
    headerName: "FG%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3PM",
    headerName: "3PM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3PA",
    headerName: "3PA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3P%",
    headerName: "3P%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FTM",
    headerName: "FTM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FTA",
    headerName: "FTA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FT%",
    headerName: "FT%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "DREB",
    headerName: "DREB",
    flex: 1,
    minWidth: 70,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "OREB",
    headerName: "OREB",
    flex: 1,
    minWidth: 70,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "REB",
    headerName: "REB",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "AST",
    headerName: "AST",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "STL",
    headerName: "STL",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "BLK",
    headerName: "BLK",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "TOV",
    headerName: "TO",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "EFF",
    headerName: "EFF",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
];

const playerSeasonStatsStatisticsHeader = [
  {
    field: "league_name",
    headerName: "LEAGUE",
    flex: 1,
    minWidth: 90,
    sortable: false,
  },
  {
    field: "season_name",
    headerName: "SEASON",
    flex: 1,
    minWidth: 90,
    sortable: false,
  },
  {
    field: "match_type",
    headerName: "TYPE",
    flex: 1,
    minWidth: 90,
    sortable: false,
  },
  {
    field: "win",
    headerName: "Win",
    flex: 1,
    minWidth: 60,
    sortable: false,
  },
  {
    field: "loss",
    headerName: "Loss",
    flex: 1,
    minWidth: 60,
    sortable: false,
  },
  {
    field: "PTS",
    headerName: "PTS",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FGM",
    headerName: "FGM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FGA",
    headerName: "FGA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FG%",
    headerName: "FG%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3PM",
    headerName: "3PM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3PA",
    headerName: "3PA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "3P%",
    headerName: "3P%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FTM",
    headerName: "FTM",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FTA",
    headerName: "FTA",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "FT%",
    headerName: "FT%",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "DREB",
    headerName: "DREB",
    flex: 1,
    minWidth: 70,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "OREB",
    headerName: "OREB",
    flex: 1,
    minWidth: 70,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "REB",
    headerName: "REB",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "AST",
    headerName: "AST",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "STL",
    headerName: "STL",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "BLK",
    headerName: "BLK",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "TOV",
    headerName: "TO",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
  {
    field: "EFF",
    headerName: "EFF",
    flex: 1,
    minWidth: 60,
    sortable: false,
    valueFormatter: ({ value }) => parseFloat(value.toFixed(1)),
  },
];

export { playerMatchStatsStatisticsHeader, playerSeasonStatsStatisticsHeader };
