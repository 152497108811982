import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./RosterProfile.scss";

const RosterProfile = (props) => {
  const history = useHistory();
  const { leagueCategory } = useSelector((store) => store.leagueReducer);

  const handleClickPlayer = (playerId) => {
    history.push(`/${leagueCategory}/players/${playerId}`);
  };

  return (
    <div
      className={`roster-player-profile-wrapper player-${props.player?.position}`}
    >
      <div
        className="roster-player-profile"
        onClick={() => {
          handleClickPlayer(props.player?.player_id);
        }}
      >
        {props.player.name}
      </div>
    </div>
  );
};

export default RosterProfile;
