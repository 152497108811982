import React, { useState } from "react";
import "./MatchSignUp.scss";
import { useDispatch } from "react-redux";
import { createMatchstats, deleteMatchstats } from "../../store/actions/match";
import ModalBox from "../Common/ModalBox";

const MatchSignUp = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const player = Object({
    seasonplayer_id: props.player.id,
    match_id: props.matchId,
  });

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const confirmMatchSignUp = () => {
    dispatch(createMatchstats([player]));
    setIsLoading(true);
  };

  const cancelSignUp = () => {
    dispatch(deleteMatchstats([props.player.id]));
    setIsLoading(true);
  };

  const title = "Confirm Match Sign Up";
  const message = `Do you confirm to sign up for this match?`;

  return (
    <div className="match-sign-up">
      <button onClick={openModal} className={props.status ? "cancel" : "play"}>
        {props.status ? (
          <span>
            Hi, {props.player.name}!<br /> Click Me <br />
            if you are NOT playing!
          </span>
        ) : (
          <span>
            Hi, {props.player.player_name}!<br /> Click Me <br />
            to sign up for this match!
          </span>
        )}
      </button>
      <ModalBox
        open={open}
        onClose={closeModal}
        title={title}
        message={message}
        onConfirm={props.status ? cancelSignUp : confirmMatchSignUp}
        onCancel={closeModal}
        loading={isLoading}
      />
    </div>
  );
};

export default MatchSignUp;
