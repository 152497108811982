import axios from "axios";

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";
export const GET_NOTICES = "GET_NOTICES";

export function getPosts(currentPage, leagueId, searchQuery) {
  return (dispatch) => {
    dispatch({ type: GET_POSTS });
    axios
      .get(
        `${
          process.env.REACT_APP_API_PATH
        }/posts/page?current_page=${currentPage}${
          leagueId !== "Show All" ? "&league_id=" + leagueId : ""
        }${
          searchQuery !== undefined && searchQuery !== ""
            ? "&search_query=" + searchQuery
            : ""
        }`
      )
      .then((res) => {
        dispatch({
          type: GET_POSTS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_POSTS_FAIL });
      });
  };
}

export function getNotices() {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/posts/status?status=Prioritized`)
      .then((res) => {
        dispatch({
          type: GET_NOTICES,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
