import {
  GET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  GET_MY_PLAYER,
  CLEAR_MY_PLAYER,
} from "../actions/user";
const initialState = {
  userInfo: null,
  playerInfo: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        userInfo: action.data,
      };
    case CLEAR_CURRENT_USER:
      return {
        ...initialState,
      };
    case GET_MY_PLAYER:
      return {
        ...state,
        playerInfo: action.data,
      };
    case CLEAR_MY_PLAYER:
      return {
        ...state,
        playerInfo: null,
      };
    default:
      return state;
  }
}
