import React, { useState } from "react";
import UserValidator from "../components/User/UserValidator";
import ChangeConfidential from "../components/User/ChangeConfidential";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeMyEmail } from "../store/actions/user";
import Loader from "react-loader-spinner";

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userToken = useSelector((store) => store.loginReducer.userToken);
  const [newEmail, setNewEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userValidator = new UserValidator();

  const handleNewEmailChange = (value) => {
    setNewEmail(value);
  };

  const handleSubmit = () => {
    if (!userValidator.validateEmailUpdateForm(newEmail)) {
      return;
    }
    setIsLoading(true);
    dispatch(changeMyEmail(userToken?.id, newEmail)).then((res) => {
      res ? history.push("/") : setIsLoading(false);
    });
  };

  return (
    <div className="confidential-page-wrapper">
      {isLoading ? (
        <div
          className="loader-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Loader
            className="post-loader"
            type="Oval"
            color="#ff5722"
            secondaryColor="#757575"
          />
          <p style={{ color: "#3a3a3a", fontWeight: 600 }}>
            Please wait!
            <br />
            We are sending a verification email to your new email...
          </p>
        </div>
      ) : (
        <ChangeConfidential
          value="Email"
          onFirstInputChange={handleNewEmailChange}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ChangeEmail;
