import React, { useEffect, useState } from "react";
import "./MatchDetailBox.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Iframe from "react-iframe";
function MatchDetailBox({ matchDetails }) {
  const [homeShootingChart, setHomeShootingChart] = useState([]);
  const [awayShootingChart, setAwayShootingChart] = useState([]);

  const baseUrl =
    process.env.REACT_APP_API_PATH === "https://dev.api.sketchbball.com"
      ? "https://dev.stats.sketchbball.com/embed/matches"
      : "https://stats.sketchbball.com/embed/matches";
  const match_id = matchDetails.match_id;
  const shared_query_params =
    "lang=en_US&hasLeadTracker=false&hasShotChart=true&hasLeader=false&hasPlayByPlay=false&hasTeamStat=false&hasBoxScore=false&isFixed=true&maxWidth=450px";
  const home_query_param = "&showHomeShotChart=true&showAwayShotChart=false";
  const away_query_param = "&showHomeShotChart=false&showAwayShotChart=true";

  const home_iframe_url = `${baseUrl}/${match_id}?${shared_query_params}${home_query_param}`;
  const away_iframe_url = `${baseUrl}/${match_id}?${shared_query_params}${away_query_param}`;

  useEffect(() => {
    if (matchDetails) {
      let homePlayers = [];
      let awayPlayers = [];
      matchDetails.home_team.match_stats?.map((player) => {
        if (
          player.name !== "Totals" &&
          player.name !== "ETC" &&
          player.is_finished
        ) {
          player.shooting_chart ? homePlayers.push(player) : null;
        }
      });

      matchDetails.away_team.match_stats?.map((player) => {
        if (
          player.name !== "Totals" &&
          player.name !== "ETC" &&
          player.is_finished
        ) {
          player.shooting_chart ? awayPlayers.push(player) : null;
        }
      });
      setHomeShootingChart(homePlayers);
      setAwayShootingChart(awayPlayers);
    }
  }, [matchDetails]);

  return (
    <div className="images-wrapper">
      <div className="grid-item-wrapper">
        <span>
          Shooting Chart -{" "}
          {matchDetails?.home_team ? matchDetails.home_team.name : null}
        </span>
        {homeShootingChart?.length ? (
          <Carousel showArrows={true} infiniteLoop={true} autoPlay={true}>
            {homeShootingChart.map((player, index) => {
              if (player.back_number === 100) player.back_number = "00";
              return (
                <div key={index} className="legacy-shooting-chart-container">
                  <div className="legacy-shooting-chart-player">{`${
                    player.back_number ?? "-"
                  } ${player.name}`}</div>
                  <img
                    src={player.shooting_chart}
                    className="carousel-item"
                    alt="carousel-item"
                  />
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className="shooting-chart-container">
            <Iframe url={home_iframe_url} className="shooting-chart-player" />
          </div>
        )}
      </div>
      <div className="grid-item-wrapper">
        <span>MVP</span>
        <img
          src={matchDetails?.mvp ? matchDetails.mvp.mvp_photo : null}
          className="mvp-image"
          alt="home-logo"
        />
      </div>
      <div className="grid-item-wrapper">
        <span>
          Shooting Chart -{" "}
          {matchDetails?.away_team ? matchDetails.away_team.name : null}
        </span>
        {awayShootingChart?.length ? (
          <Carousel showArrows={true} infiniteLoop={true} autoPlay={true}>
            {awayShootingChart.map((player, index) => {
              if (player.back_number === 100) player.back_number = "00";
              return (
                <div key={index} className="shooting-chart-container">
                  <div className="shooting-chart-player">{`${
                    player.back_number ?? "-"
                  } ${player.name}`}</div>
                  <img
                    src={player.shooting_chart}
                    className="carousel-item"
                    alt="carousel-item"
                  />
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className="shooting-chart-container">
            <Iframe url={away_iframe_url} className="shooting-chart-player" />
          </div>
        )}
      </div>
    </div>
  );
}

export default MatchDetailBox;
