import React, { useState, useEffect } from "react";
import "./Match.scss";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMatch,
  getAwayCaptains,
  getAwayCoaches,
  getAwayTeamRoster,
  getHomeCaptains,
  getHomeCoaches,
  getHomeTeamRoster,
  getMatchDetails,
  getMatchPhotos,
} from "../store/actions/match";
import { getMyPlayer } from "../store/actions/user";
import { matchStatDefaultHeader } from "../const/matchStatDefaultHeader";
import GameResult from "../components/Match/GameResult";
import MatchDetailBox from "../components/Match/MatchDetailBox";
import YoutubeEmbed from "../components/Common/YoutubeEmbed";
import TeamRoster from "../components/Team/TeamRoster";
import MatchRoster from "../components/Match/MatchRoster";
import MatchSignUp from "../components/Match/MatchSignUp";
import MatchPhotos from "../components/Match/MatchPhotos";
import { TodayISO, MatchLimitISO } from "../const/DatetimeISO";

const Match = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { matchDetails, homeTeamRoster, awayTeamRoster, matchPhotos } =
    useSelector((store) => store.matchReducer);
  const { userToken } = useSelector((store) => store.loginReducer);
  const { playerInfo } = useSelector((store) => store.userReducer);
  const [currentPlayer, setCurrenetPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeLimit, setTimeLimit] = useState("");
  const [isStarted, setIsStarted] = useState(true);

  const today = TodayISO();

  useEffect(() => {
    if (today <= timeLimit) {
      setIsStarted(false);
    }
  }, [timeLimit]);

  useEffect(() => {
    if (userToken) {
      dispatch(getMyPlayer(userToken.id));
    }
  }, [userToken]);

  useEffect(() => {
    dispatch(getMatchDetails(id));
    return () => {
      dispatch(clearMatch());
    };
  }, []);

  useEffect(() => {
    if (matchDetails) {
      dispatch(
        getHomeTeamRoster(matchDetails.home_team.id, matchDetails.season_id)
      );
      dispatch(
        getAwayTeamRoster(matchDetails.away_team.id, matchDetails.season_id)
      );
      dispatch(getHomeCaptains(matchDetails.home_team.id));
      dispatch(getAwayCaptains(matchDetails.away_team.id));
      dispatch(getHomeCoaches(matchDetails.home_team.id));
      dispatch(getAwayCoaches(matchDetails.away_team.id));
    }
  }, [matchDetails]);

  useEffect(() => {
    if (matchDetails) {
      dispatch(getMatchPhotos(id));
    }
  }, [matchDetails]);

  useEffect(() => {
    if (!currentPlayer && !isStarted) {
      homeTeamRoster?.map((player) => {
        if (player.player_id === playerInfo?.id) {
          setCurrenetPlayer(player);
          return;
        }
      });

      awayTeamRoster?.map((player) => {
        if (player.player_id === playerInfo?.id) {
          setCurrenetPlayer(player);
          return;
        }
      });

      let terminator = false;
      matchDetails?.home_team.match_stats.map((player) => {
        if (player.player_id === playerInfo?.id) {
          setCurrenetPlayer(player);
          setIsPlaying(true);
          terminator = true;
          return;
        }
      });

      if (!terminator) {
        matchDetails?.away_team.match_stats.map((player) => {
          if (player.player_id === playerInfo?.id) {
            setCurrenetPlayer(player);
            setIsPlaying(true);
            return;
          }
        });
      }
    }
  }, [playerInfo, homeTeamRoster, awayTeamRoster]);

  useEffect(() => {
    if (matchDetails) {
      setTimeLimit(MatchLimitISO(matchDetails));
    }
  }, [matchDetails]);

  return (
    <div className="match-container">
      <div className="match-video-wrapper" style={{ backgroundColor: "black" }}>
        <div className="match-video">
          <YoutubeEmbed
            embedId={matchDetails ? matchDetails.match_videos : "PlLX5dK-AzQ"}
          />
        </div>
      </div>
      {currentPlayer && !isStarted && (
        <>
          <div className="notice-message">
            Please note that the match roster registration will be closed at{" "}
            {timeLimit.replace("T", " ").slice(0, 16)}
            .<br />
            If you have any enquiry, please contact us through the team captain.
          </div>
          <MatchSignUp player={currentPlayer} matchId={id} status={isPlaying} />
        </>
      )}
      <div className="match-content-wrapper">
        <GameResult matchDetails={matchDetails} />
        <div className="match-photos">
          <MatchPhotos matchPhotos={matchPhotos} />
        </div>
        <MatchRoster matchId={id} isStarted={isStarted} />
        {matchDetails?.mvp && <MatchDetailBox matchDetails={matchDetails} />}
        {matchDetails?.home_score != "-" || matchDetails.away_score != "-" ? (
          <div className="box-scores">
            <div className="box-score-wrapper">
              <span>{matchDetails ? matchDetails.home_team.name : null}</span>
              <DataGrid
                rows={matchDetails ? matchDetails.home_team.match_stats : []}
                columns={matchStatDefaultHeader}
                autoHeight={true}
                rowHeight={50}
                pageSize={100}
                rowsPerPageOptions={[100]}
              />
            </div>
            <div className="box-score-wrapper">
              <span>{matchDetails ? matchDetails.away_team.name : null}</span>
              <DataGrid
                rows={matchDetails ? matchDetails.away_team.match_stats : []}
                columns={matchStatDefaultHeader}
                autoHeight={true}
                rowHeight={50}
                pageSize={100}
                rowsPerPageOptions={[100]}
              />
            </div>
          </div>
        ) : (
          <div className="rosters">
            <TeamRoster roster={homeTeamRoster} />
            <TeamRoster roster={awayTeamRoster} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Match;
